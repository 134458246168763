/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.cb-mobile-navigation {
  display: none;
}
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  white-space: nowrap;
  display: block;
  padding: 1em;
  border: solid #8ad7c8;
  border-width: 0 1px 1px 0;
  background: #fff;
  color: #64cab6;
  text-align: center;
  font-family: 'PlayfairDisplayRegular', georgia, serif;
  -webkit-transition: all 0.218s ease-out;
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
}
.menu:active {
  border-color: #7ad1c0 !important;
  background: #7ad1c0 !important;
}
.init > .menu {
  border-left-width: 1px;
}
a.path,
.menu:hover,
.menu:focus {
  color: #fff;
  background: #8ad7c8;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
/* LEVEL 1 */
div.sub1 {
  width: 100%;
}
.sub1 > .item {
  width: 20%;
}
/* LEVEL 2 */
div.sub2 {
  width: 100%;
}
#edit div.sub2 {
  opacity: 0.6;
}
#view div.sub2 {
  display: none;
}
#services {
  font-size: 14px;
  font-size: 1.4rem;
  float: left;
  margin-left: 3.90625%;
  padding: 10px 0 20px;
  width: 100%;
  width: 92.1875%;
  color: #43bea6;
  text-align: left;
  text-transform: lowercase;
  font-family: 'QuicksandLight', sans-serif;
}
#services .meta {
  color: #a1dfd3;
}
/******* layout-large.less 2013-1-16 *******/
.header .cb-home {
  float: left;
  position: static;
  margin-bottom: 4px;
  width: 12.16931217%;
}
.cb-page-color-c1.cb-page-first .header .cb-home {
  margin-top: 90px;
}
.cb-page-layout1 .header .cb-home {
  position: relative;
  z-index: 1;
  margin: -19.13265306% 0 0 50% !important;
  width: 36.93877551%;
  max-width: 1001px;
  opacity: 0.7;
}
.cb-page-layout1 .header .cb-home .logo {
  margin-left: -50%;
}
.cb-page-color-c3.cb-page-layout1 .header .cb-home,
.cb-page-color-c5.cb-page-layout1 .header .cb-home {
  opacity: 1;
}
.cb-page-color-c4.cb-page-layout1 .header .cb-home,
.cb-page-color-c5.cb-page-layout1 .header .cb-home {
  width: 18.57142857%;
}
.cb-page {
  margin-top: 90px !important;
}
.cb-page.cb-page-layout1.cb-page-first,
.cb-page.cb-page-layout2.cb-page-first {
  margin-top: 0 !important;
}
.cb-page-title {
  margin-left: 1.05820106%;
  width: 85.83068783%;
}
.desk {
  min-width: 640px;
  max-width: 2564px;
}
.header {
  width: 96.42857143%;
  margin-left: 1.78571429%;
}
.cb-mood {
  background-size: cover;
}
#view .cb-page-first .cb-mood {
  margin-top: 40px;
}
.content {
  margin-bottom: -90px;
}
.cb-page-last .content {
  margin-bottom: 0;
}
.basewrapper {
  background: #e4f5f1;
}
.modedubach {
  font-size: 24px;
  font-size: 2.4rem;
}
.fn {
  padding-bottom: 10%;
  margin: 26px 0 19px;
  padding-bottom: 6.5%;
}
.modedubach-hours {
  font-size: 19px;
  font-size: 1.9rem;
}
.adr {
  margin-top: 3em;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0.84745763%;
  margin-left: 0.84745763%;
}
.area .part,
.area > .grid table {
  margin-right: 0.84745763%;
  margin-left: 0.84745763%;
  width: 98.30508475%;
}
.area .tiny {
  width: 48.30508475%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 98.30508475%;
}
.area > .slim .tiny {
  width: 23.30508475%;
}
.cb-page-layout2 .area {
  width: 100%;
}
.cb-page-layout2 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .area h2,
.cb-page-layout2 .area .foot {
  margin-right: 0.84745763%;
  margin-left: 0.84745763%;
}
.cb-page-layout2 .area .part,
.cb-page-layout2 .area > .grid table {
  margin-right: 0.84745763%;
  margin-left: 0.84745763%;
  width: 98.30508475%;
}
.cb-page-layout2 .area .tiny {
  width: 48.30508475%;
}
.cb-page-layout2 .area > .slim {
  width: 50%;
}
.cb-page-layout2 .area > .slim h2,
.cb-page-layout2 .area > .slim .foot,
.cb-page-layout2 .area > .slim .part,
.cb-page-layout2 .area > .slim.grid table {
  margin-right: 1.69491525%;
  margin-left: 1.69491525%;
}
.cb-page-layout2 .area > .slim .part,
.cb-page-layout2 .area > .slim.grid table {
  width: 96.61016949%;
}
.cb-page-layout2 .area > .slim .tiny {
  width: 96.61016949%;
}
.cb-page-layout5 .area {
  width: 100%;
}
.cb-page-layout5 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout5 .area h2,
.cb-page-layout5 .area .foot {
  margin-right: 1.12781955%;
  margin-left: 1.12781955%;
}
.cb-page-layout5 .area .part,
.cb-page-layout5 .area > .grid table {
  margin-right: 1.12781955%;
  margin-left: 1.12781955%;
  width: 97.7443609%;
}
.cb-page-layout5 .area .tiny {
  width: 31.07769424%;
}
.cb-page-layout5 .area > .slim {
  width: 33.33333333%;
}
.cb-page-layout5 .area > .slim h2,
.cb-page-layout5 .area > .slim .foot,
.cb-page-layout5 .area > .slim .part,
.cb-page-layout5 .area > .slim.grid table {
  margin-right: 3.38345865%;
  margin-left: 3.38345865%;
}
.cb-page-layout5 .area > .slim .part,
.cb-page-layout5 .area > .slim.grid table {
  width: 93.23308271%;
}
.cb-page-layout5 .area > .slim .tiny {
  width: 93.23308271%;
}
.main > .wide .part {
  width: 48.30508475%;
}
.main > .wide .pict.tiny {
  width: 23.30508475%;
}
.cb-page-layout4 .main > .wide {
  width: 50%;
}
.cb-page-layout4 .main > .wide h2,
.cb-page-layout4 .main > .wide .foot,
.cb-page-layout4 .main > .wide .part {
  margin-right: 1.69491525%;
  margin-left: 1.69491525%;
}
.cb-page-layout4 .main > .wide .part {
  width: 96.61016949%;
}
.cb-page-layout1 .main > .slim h2,
.cb-page-layout3 .main > .slim h2,
.cb-page-layout4 .main > .slim h2,
.cb-page-layout1 .main > .slim .foot,
.cb-page-layout3 .main > .slim .foot,
.cb-page-layout4 .main > .slim .foot,
.cb-page-layout1 .main > .slim .part,
.cb-page-layout3 .main > .slim .part,
.cb-page-layout4 .main > .slim .part {
  margin-right: 1.12781955%;
  margin-left: 1.12781955%;
}
.cb-page-layout1 .main > .slim .part,
.cb-page-layout3 .main > .slim .part,
.cb-page-layout4 .main > .slim .part {
  width: 97.7443609%;
}
.cb-page-layout1 .main > .slim .tiny,
.cb-page-layout3 .main > .slim .tiny,
.cb-page-layout4 .main > .slim .tiny {
  width: 31.07769424%;
}
.south .part {
  width: 31.63841808%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.content .main {
  margin-left: 0.83333333%;
  width: 98.33333333%;
}
.cb-page-layout5 .content .main {
  padding-bottom: 20px;
  margin-left: 13.05555556%;
  width: 73.88888889%;
}
.cb-page-layout1 .area > .slim,
.cb-page-layout3 .area > .slim,
.cb-page-layout4 .area > .slim {
  padding: 0 12.42937853%;
  width: 75.14124294%;
}
.cb-page-layout5 .cb-page-layout1 .area > .slim,
.cb-page-layout5 .cb-page-layout3 .area > .slim,
.cb-page-layout5 .cb-page-layout4 .area > .slim {
  padding: 0;
}
.base {
  min-width: 640px;
  max-width: 2564px;
}
.base .unit {
  margin-top: 0;
  margin-bottom: 0;
}
#view .base {
  float: none;
  margin: 0 auto;
}
.pict > a:hover:after,
.pict > a:focus:after {
  font-size: 40px;
  font-size: 4rem;
}
#view .cb-page-layout4 .main > .wide.seam .link > .open:hover,
#view .cb-page-layout4 .main > .wide.seam .link > .open:focus {
  font-size: 40px;
  font-size: 4rem;
}
#view .cb-page-layout5 .main > .slim.seam .link > .open:hover,
#view .cb-page-layout5 .main > .slim.seam .link > .open:focus {
  font-size: 20px;
  font-size: 2rem;
}
.edge:after {
  left: 0.84745763%;
  width: 98.30508475%;
}
.edge.slim:after {
  left: 1.10803324%;
  width: 97.78393352%;
}
.cb-page-layout4 .edge.wide:after {
  left: 1.69491525%;
  width: 96.61016949%;
}
.slim.edge {
  background: #e4f5f1;
}
.slim.edge:after {
  display: none;
}
.slim.edge .body,
.slim.edge .part {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-page-layout2 .edge.wide + .slim,
.cb-page-layout2 .edge.wide + .slim + .slim {
  margin-top: -20px;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */